/* ==============
  Tables
===================*/
th {
  font-weight: 500;
}

.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > td {
  padding: 15px 12px;
}

.table-hover tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd),
.thead-default th {
  > * {
    --bs-table-accent-bg: rgb(0 0 0 / 4%);
  }
}
.table > :not(caption) > * > * {
  background-color:transparent !important;
  background: transparent;
}
.table td,
.table th {
  vertical-align: middle;
}

.table-vertical {
  td {
    vertical-align: middle;
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem !important;
  }
}

/* == Responsive Table ==*/
table.focus-on {
  tbody {
    tr.focused {
      th {
        // background-color: $primary;
        color: $white;
      }
      td {
        // background-color: $primary;
        color: $white;
      }
    }
  }
}
.table-scroll {
  position: relative;
  max-height: calc(100vh - 18.9rem);
  padding-right: 0.5rem;
}
.table-rep-plugin {
  .btn-toolbar {
    display: block;
  }

  .table-responsive {
    border: none !important;
  }  

  .btn-group.float-right .dropdown-menu {
    right: 0;
    transform: none !important;
    top: 100% !important;
  }

  tbody {
    th {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .checkbox-row {
    padding-left: 40px;

    label {
      display: inline-block;
      padding-left: 5px;
      position: relative;

      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $gray-300;
        content: '';
        display: inline-block;
        height: 17px;
        left: 0;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none !important;
      }

      &::after {
        color: $gray-200;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -1px;
        width: 16px;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled + label {
        opacity: 0.65;
      }
    }

    input[type='checkbox']:focus + label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }

    input[type='checkbox']:checked + label {
      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }

    input[type='checkbox']:disabled + label {
      &::before {
        background-color: $gray-100;
        cursor: not-allowed;
      }
    }

    input[type='checkbox']:checked + label {
      &::before {
        background-color: $primary;
        border-color: $primary;
      }

      &::after {
        color: $white;
      }
    }
  }

  .fixed-solution {
    .sticky-table-header {
      top: 70px !important;
    }
  }
}

/******** table editable **********/

.table-editable {
  .editable-input {
    .form-control {
      height: 2rem;
    }
  }

  .editable-buttons {
    .btn {
      &.btn-sm {
        font-size: 12px;
      }
    }
  }

  tbody {
    td {
      &.focus {
        box-shadow: inset 0 0 1px 1px $primary !important;
      }
    }
  }
}

/******** table-editable **********/
.dataTables_wrapper {
  .dataTables_length {
    width: 100%;
    display: inline-block;
    margin-bottom: 0.5rem;
    label {
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      white-space: pre;
      .custom-select {
        padding: 0.5rem 1.75rem 0.5rem 0.75rem;
        height: auto;
        line-height: normal;
        //background: url(../assets/images/down-arrow.svg) no-repeat center right 0.5rem / 0.8rem;
        -webkit-box-shadow: none;
      }
    }
  }
  .mdb-datatable-filter {
    position: relative;
    margin-bottom: 0.5rem;
    &::before {
      content: '';
      background: url('../assets/images/search.png') no-repeat;;
      width: 2rem;
      height: 2rem;
      background-size: 1.5rem;
      background-position: center left;      
      position: absolute;
      left: 0.4rem;
      top: 50%;     
      transform: translateY(-50%);      
    }
    .form-control {
      padding: 0.5rem 0.75rem 0.5rem 2.15rem;
      height: auto;
      line-height: normal;
      margin: 0 !important;
      width: 100%;
      // background: transparent url('../assets/images/search.png') no-repeat 5px center;
      // background-size: 13%;
    }
  }
  .dataTables_info {
    height: calc(100% - 1rem);
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
  .dataTables_paginate {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .pagination {
      margin: 0 !important;
      display: inline-flex;
    }
  }

  table.table.dataTable,
  .custom_table table.table {
    border: 0;
    margin: 0;
    thead {
      position: sticky;
      top: -1px;
      background: $primary;
      z-index: 1;
      tr {
        th {
          white-space: pre;
          color: $white;
          border: 0;
          border-right: 1px solid lighten($primary, 10%);
          font-weight: normal;
          letter-spacing: 0.4px;
          font-size: 1rem;
          &:first-child {
            border-radius: 10px 0 0 0;
            border-right: 1px solid lighten($primary, 10%);
          }
          &:last-child {
            border-radius: 0 10px 0 0;
            border-right: none;
          }
          &.active_switch {
            width: 65px;
          }
          &.action_col {
            width: 120px;
            text-align: center;
          }
          &.sorting {
            padding: 0.75rem 2rem 0.75rem 0.75rem;
          }
          &.sorting::after {
            content: '\2191\2193';
          }
          &.sorting_asc::after {
            content: '\2191';
          }
          &.sorting_desc::after {
            content: '\2193';
          }
          &::after {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            bottom: 0.9em;
            display: inline-block;
            font-weight: normal;
            font-size: 13px;
            height: 25px;
            padding-left: 0.5rem;
          }
        }
      }
    }
    tbody {
      tr {
        &:hover,
        &:focus {
          background: lighten($primary, 63%);
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 10px;
            }
            &:last-child {
              border-radius: 0 0 10px 0;
            }
          }
        }
        td {
          vertical-align: top;
          padding: 0.3rem 0.4rem;
          .descriptionTable {
            width: 80ch;
          }
          .btn-link {
            cursor: pointer !important;
          }
        }
      }
    }
  }
}
/******** End-table-editable **********/
.action_col {
  width: auto;
  min-width: 4rem;
  line-height: normal;
  &.vendor-contacts-table {
    min-width: 9rem;
  }
  .btn {
    padding: 0.3rem;
    &:hover,
    &:focus {
      color: $orange;
    }
  }
  .history_btn {
    color: $primary;
    svg {
      width: 1.2rem;
    }
  }
  .edit_btn {
    color: $success;
    svg {
      width: 1.2rem;
    }
  }
  .resend_btn {
    color: $orange;
    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.store_col {
  min-width: 20ch;
}
.source_col {
  min-width: 12ch;
}
.vendor_col {
  min-width: 15ch;
}

.table{
  .role-badge {
    background: lighten($primary, 20%);
    color: $white;
    font-weight: 600;
    border-radius: 100px;
    letter-spacing: 0.5px;
    line-height: normal;
    padding: 0.25rem 0.5rem;
    width: 6.1rem;
  }
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0;
  > .record-perpage {
    display: flex;
    align-items: center;
    padding: 0;
    .dropdown {
      .btn {
        background: transparent;
        color: #212121;
        border-radius: 5px;
        min-width: 3.1rem;
        padding: 0.2rem 0.5rem;
        border-color: #b2b6bb;
        &:hover,
        &:focus {
          transform: none !important;
        }
      }
    }
    .totle_records {
      padding: 0 0 0 0.5rem;
      font-size: 0.8rem;
      line-height: normal;
    }
  }
}

.custom_table table.table,
.react-bootstrap-table table.table {
  border: 0;
  margin: 0;  
  thead {
    position: sticky;
    top: -15px;
    background: $primary;
    z-index: 1;
    tr {
      th {
        white-space: pre;
        color: $white;
        border: 0;
        padding: 0.5rem !important;
        border-right: 1px solid lighten($primary, 10%);
        border-top: 1px solid transparent;
        border-bottom: 1px solid $primary;
        font-weight: normal;
        letter-spacing: 0.4px;
        font-size: 1rem;
        padding-right: 1.6rem !important;
        line-height: normal;
        &:first-child {
          border-radius: 10px 0 0 0;
          border-right: 1px solid lighten($primary, 10%);
        }
        &:last-child {
          border-radius: 0 10px 0 0;
          border-right: none;
        }
        &.sortable {
          position: relative;
          span {
            &::after {
              content: '\2191\2193';
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              bottom: 0.9em;
              right: 0.5rem;
              display: inline-block;
              font-weight: normal;
              font-size: 13px;
              height: 17px;
              font-family: 'FontAwesome free';
              padding-left: 0.5rem;
            }
          }
          span[class*='asc'] {
            &::after {
              content: '\2191';
            }
          }
          span[class*='desc'] {
            &::after {
              content: '\2193';
            }
          }
        }
        .custom-control {
          text-align: center;
          z-index: 0;
          &.custom-checkbox {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-height: 1rem;
            padding-left: 1rem;
            position: relative;
            .custom-control-input {
              width: 1rem;
              height: 1rem;
              z-index: 1;
              &:focus {
                box-shadow: none;
              }
            }
            .custom-control-label {
              position: absolute;
              left: 0;
              top: 0;
              width: 1rem;
              height: 1rem;
              &::before {
                left: 0;
                top: 0;
                box-shadow: none;
              }
              &::after {
                left: 0;
                top: 0;
              }
            }
            .custom-control-input:checked ~ .custom-control-label {
              &:before {
                background-color: #ffffff;
              }
              &:after {
                filter: brightness(0) invert(0);
              }
            }
          }
        }
        &.upload_col {
          width: 25rem;
        }
        &.details_col {
          min-width: 30vw;
        }
      }
    }
  }
  tbody {
    tr {
      &:hover,
      &:focus {
        background-color: lighten($primary, 70%) !important;
      }
      &:last-child {
        // border: none;
        td {
          border-bottom: none;
          &:first-child {
            border-radius: 0 0 0 10px;
          }
          &:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }
      td {
        vertical-align: middle;
        padding: 0.3rem 0.4rem;
        color: #667085;
        box-shadow: none;
        .first_name_td {
          p {
            color: $dark;
          }
        }
        .descriptionTable {
          width: 80ch;
        }
        .tbl_action {
          display: flex;
        }
        .custom-control {
          text-align: center;
          z-index: 0;
          button {
            &:focus {
              box-shadow: none;
            }
          }
          &.custom-checkbox {
            margin: 0 auto;
            display: block;
            width: 1rem;
            min-height: 1rem;
            padding-left: 1rem;
            position: relative;
            .custom-control-input {
              width: 1rem;
              height: 1rem;
              z-index: 1;
              &:focus {
                box-shadow: none;
              }
            }
            .custom-control-label {
              position: absolute;
              left: 0;
              top: 0;
              width: 1rem;
              height: 1rem;
              &::before {
                left: 0;
                top: 0;
                box-shadow: none;
              }
              &::after {
                left: 0;
                top: 0;
              }
              .custom-control-input:checked ~ .custom-control-label {
                &:before {
                  background-color: #ffffff;
                }
                &:after {
                  filter: brightness(0) invert(0);
                }
              }
            }
          }
        }
      }
      &:nth-child(even){
        background-color: #e7f4fa;
      }
      .status-select {
        &.form-control {
          height: 2rem;
          width: 11rem;
          font-size: 0.8rem;
          [class*=control] {
            min-height: 2rem;
            max-height: 2rem;
            width: auto;
            > div {
              max-height: 2rem;
              overflow-y: auto;
              padding: 0 0 0 3px;
              div {
                padding: 0;
                margin: 0;
                svg {
                  width: 1.1rem;
                  height: 1.1rem;
                }
              }
            }
            [class*=indicatorContainer] {
              padding-block: 0px;
            }
          }
          [class*=menu] {
            margin-bottom: 4px;
            margin-top: 4px;
            [class*=option] {
              padding: 0.4rem 0.5rem;
              &:not(:last-child) {
                border-bottom: 1px solid rgba($primary, 0.10); 
              }
              &:is(:hover, :focus) {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.JoditEditor-border thead, .JoditEditor-border tbody, .JoditEditor-border tfoot, .JoditEditor-border tr, .JoditEditor-border td, .JoditEditor-border th {
  border-width: 1px;
  border-collapse: collapse;
  padding: 0.4em;
}

.ach_table{
  .table{
    thead{
      display: table;
      margin: 0 0 0.3rem;
      border-radius: 7px;
      max-width: 100%;
      overflow: hidden;
      tr{
        display: inline-flex;
        th{
          width: calc(100vw - 83.7vw);
          border: none;
          &:last-child{
            width: calc(100vw - 87.4vw);
          }
        }
      }
    }
    tbody{
      background: transparent;
      tr{
        margin: 0.2rem 0;
        // background: white;
        border-radius: 7px;
        overflow: hidden;
        display: inline-block;
        border: none;
        &.pending_ach{
          background: #DBE1EB;
        }
        &.approved_ach{
          background: #DBEBDB;
        }
        &.rejected_ach{
          background: #EBDBDB;
        }
        &:nth-child(even){
          &.pending_ach{
            background: #DBE1EB;
          }
          &.approved_ach{
            background: #DBEBDB;
          }
          &.rejected_ach{
            background: #EBDBDB;
          }
        }
        td{
          width: calc(100vw - 83.7vw);
          border: none;
          padding: 0.2rem 0.6rem !important;
          color: #000 !important;
          &:last-child{
            width: calc(100vw - 87.4vw);
          }
        }
      }
    }
  }
}